import React from 'react';

const Pinboard = ({ pinnedStocks, onPinStock, onShowPinned, showPinned }) => {
  return (
    <div className="bg-gray-700 p-4 rounded-lg shadow-lg mb-4">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-bold text-gray-300">Pinned Stocks</h2>
        <button 
          onClick={pinnedStocks.length > 0 ? onShowPinned : null} 
          className={`bg-blue-500 hover:bg-blue-600 text-white px-2 py-1 rounded ${pinnedStocks.length === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={pinnedStocks.length === 0}
        >
          {showPinned ? 'Unshow Pinned' : 'Show Pinned'}
        </button>
      </div>
      <ul className="mt-2">
        {pinnedStocks.length === 0 ? (
          <li className="text-gray-400">No stocks pinned.</li>
        ) : (
          pinnedStocks.map((stock) => (
            <li key={stock.symbol} className={`flex justify-between items-center text-gray-200`}>
              <span>{stock.symbol}</span>
              <button onClick={() => onPinStock(stock)} className="text-red-500">Unpin</button>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default Pinboard;
