import React, { useState, useEffect, useCallback } from 'react';
import { Send, TrendingUp, MessageSquare, LogIn, LogOut, Lock, Loader, Trash2, DollarSign, BarChart2, Shield } from 'lucide-react';
import Markdown from 'markdown-to-jsx';
import { InlineMath, BlockMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import StockDataTable from './StockDataTable';
import { fetchFromBackend } from './api';
import { BACKEND_URL } from './api';

const StockAIChat = () => {
  const [selectedStock, setSelectedStock] = useState('GME');
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [user, setUser] = useState(null);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [authMode, setAuthMode] = useState('login'); // 'login' or 'signup'
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPremiumPrompt, setShowPremiumPrompt] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const stocks = ['GME', 'AAPL', 'GOOGL', 'MSFT', 'AMZN', 'FB'];

  const suggestedPrompts = {
    general: [
      "Summarize recent events after the last earnings and mention how each impacts GameStop.",
      "Using the fundamentals outlined in Graham and Dodd's Security Analysis, provide a Security Analysis for GameStop.",
    ],
    financial: [
      "What are the largest liabilities on GameStop's balance sheet, and how have they changed year-over-year?",
      "Identify any significant changes in GameStop's cash flow from operations. What caused these changes?",
      "Evaluate the quality of GameStop's earnings by identifying any non-recurring or unusual income or expense items in the last year.",
    ],
    marginOfSafety: [
      "What is GameStop's asset breakdown? Provide an analysis on liquid assets vs nonliquid and whether the valuation of said assets is accurate. Provide a true range of what its assets and liquid assets may be worth and a confidence level."
    ],
    ratios: [
      "What is the ratio of GameStop's current assets to its current liabilities, and what does this indicate about its liquidity?",
      "What is GameStop's debt-to-equity ratio, and how does this affect its financial risk?",
    ],
    visualization: [
      "Plot 4 quarters of GME revenue/earnings.",
      "Pie chart of GameStop's revenue breakdown by product/sales segment, and another one for its margin breakdown by segment. Also provide its blended margin.",
      "Key financials for GameStop?",
    ],
    compliance: [
      "Highlight any material weaknesses in internal controls over financial reporting that GameStop disclosed.",
    ],
  };

  useEffect(() => {
    // ... (rest of the useEffect code remains the same)
    const token = localStorage.getItem('token');
    if (token) {
      fetchUserData(token);
    }

    // Tawk.to Integration
    var s1 = document.createElement("script");
    var s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = 'https://embed.tawk.to/66c84be250c10f7a009f9965/1i5v6grpq';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);

    // Cleanup function
    return () => {
      // Remove Tawk.to script when component unmounts
      if (s1 && s1.parentNode) {
        s1.parentNode.removeChild(s1);
      }
      // Reset Tawk_API when component unmounts
      window.Tawk_API = undefined;
    };
  }, []);

  const fetchUserData = async (token) => {
    try {
      const response = await fetch(`${BACKEND_URL}/api/user`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        const userData = await response.json();
        setUser(userData);
      } else {
        localStorage.removeItem('token');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const addMessageToChat = (content, type, update = false) => {
    if (chatHistory.length >= 3 && !user) {
      setShowAuthModal(true);
      return;
    }
    setMessage('');
    setChatHistory(prev => {
      if (update && prev.length > 0 && prev[prev.length - 1].type === type) {
        const newHistory = [...prev];
        newHistory[newHistory.length - 1].content = content;
        return newHistory;
      }
      return [...prev, { type, content }];
    });
  };

  const sendMessageToBackend = useCallback(async (messageToSend) => {
    setIsLoading(true);
    try {
      const response = await fetchFromBackend('/prompt', 'POST', { message: messageToSend });
      
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let chunks = [];

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });
        console.log('Received chunk:', chunk);
        chunks.push(chunk);
        const joinedChunks = chunks.join('');
        addMessageToChat(joinedChunks, 'ai', true);
      }
    } catch (error) {
      console.error('Error details:', error);
      addMessageToChat('Sorry, there was an error processing your request.', 'ai');
    } finally {
      setIsLoading(false);
    }
  }, [fetchFromBackend, addMessageToChat]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (message.trim() !== '') {
      addMessageToChat(message, 'user');
      await sendMessageToBackend(message);
      setMessage(''); // Clear the input field after sending
    }
  };

  const handlePromptClick = async (prompt) => {
    addMessageToChat(prompt, 'user');
    await sendMessageToBackend(prompt);
  };

  const handleAuth = async (e) => {
    e.preventDefault();
    const endpoint = authMode === 'login' ? 'login' : 'signup';
    try {
      const response = await fetch(`${BACKEND_URL}/api/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.token);
        fetchUserData(data.token);
        setShowAuthModal(false);
        setShowPremiumPrompt(false);
      } else {
        // Handle error
        console.error('Authentication failed');
      }
    } catch (error) {
      console.error('Error during authentication:', error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setUser(null);
    setChatHistory([]);
    setSelectedStock('GME');
  };

  const handleStockChange = (e) => {
    if (!user && e.target.value !== 'GME') {
      setShowPremiumPrompt(true);
    } else {
      setSelectedStock(e.target.value);
    }
  };

  const renderMarkdown = (markdown) => {
    return (
      <Markdown
        options={{
          overrides: {
            h1: {
              component: ({ children, ...props }) => (
                <h1 {...props} className="fancy-h1">
                  {children}
                  <div className="fancy-underline"></div>
                </h1>
              ),
            },
            h2: {
              component: ({ children, ...props }) => (
                <h2 {...props} className="fancy-h2">
                  <span className="fancy-h2-marker">◆</span> {children}
                </h2>
              ),
            },
            p: {
              component: ({ children, ...props }) => (
                <p {...props} className="fancy-p">
                  {children}
                </p>
              ),
            },
            a: {
              component: ({ children, ...props }) => (
                <a {...props} className="fancy-link">
                  {children}
                </a>
              ),
            },
            ul: {
              component: ({ children, ...props }) => (
                <ul {...props} className="fancy-ul">
                  {children}
                </ul>
              ),
            },
            ol: {
              component: ({ children, ...props }) => (
                <ol {...props} className="fancy-ol">
                  {children}
                </ol>
              ),
            },
            li: {
              component: ({ children, ...props }) => (
                <li {...props} className="fancy-li">
                  {children}
                </li>
              ),
            },
            blockquote: {
              component: ({ children, ...props }) => (
                <blockquote {...props} className="fancy-blockquote">
                  {children}
                </blockquote>
              ),
            },
            code: {
              component: ({ children, ...props }) => (
                <code {...props} className="fancy-code">
                  {children}
                </code>
              ),
            },
            img: {
              component: ({ alt, src, ...props }) => (
                <div className="fancy-img-container">
                  <img alt={alt} src={src} {...props} className="fancy-img" />
                  {alt && <span className="fancy-img-caption">{alt}</span>}
                </div>
              ),
            },
            table: {
              component: ({ children, ...props }) => (
                <div className="fancy-table-container">
                  <table {...props} className="fancy-table">
                    {children}
                  </table>
                </div>
              ),
            },
            inlinemath: {
              component: InlineMath,
            },
            blockmath: {
              component: BlockMath,
            },
          },
        }}
      >
        {markdown}
      </Markdown>
    );
  };

  const clearChat = () => {
    setChatHistory([]);
  };

  const getCategoryIcon = (category) => {
    switch (category) {
      case 'general':
        return <MessageSquare className="text-blue-400" />;
      case 'financial':
        return <DollarSign className="text-green-400" />;
      case 'marginOfSafety':
        return <Shield className="text-orange-400" />;
      case 'ratios':
        return <TrendingUp className="text-yellow-400" />;
      case 'visualization':
        return <BarChart2 className="text-purple-400" />;
      case 'compliance':
        return <MessageSquare className="text-red-400" />;
      default:
        return null;
    }
  };

  const getCategoryTitle = (category) => {
    return category.charAt(0).toUpperCase() + category.slice(1);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-gray-900 to-blue-900 text-white p-6">      <style jsx>{`
        @keyframes gradient {
          0% { background-position: 0% 50%; }
          50% { background-position: 100% 50%; }
          100% { background-position: 0% 50%; }
        }
        .animate-gradient {
          background: linear-gradient(270deg, #3b82f6, #8b5cf6, #ec4899, #3b82f6);
          background-size: 300% 300%;
          animation: gradient 8s ease infinite;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        /* Fancy Markdown Styles */
        .fancy-h1 {
          font-size: 2.5em;
          color: #4a90e2;
          margin-bottom: 0.5em;
          position: relative;
        }

        .fancy-underline {
          position: absolute;
          bottom: -5px;
          left: 0;
          width: 100%;
          height: 3px;
          background: linear-gradient(90deg, #4a90e2, #50e3c2);
        }

        .fancy-h2 {
          font-size: 1.8em;
          color: #50e3c2;
          margin-top: 1em;
          margin-bottom: 0.5em;
        }

        .fancy-h2-marker {
          color: #4a90e2;
          margin-right: 0.5em;
        }

        .fancy-p {
          line-height: 1.6;
          margin-bottom: 1em;
          text-align: justify;
        }

        .fancy-link {
          color: #4a90e2;
          text-decoration: none;
          position: relative;
          transition: color 0.3s;
        }

        .fancy-link::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: 0;
          left: 0;
          background-color: #50e3c2;
          transform: scaleX(0);
          transition: transform 0.3s;
        }

        .fancy-link:hover {
          color: #50e3c2;
        }

        .fancy-link:hover::after {
          transform: scaleX(1);
        }

        .fancy-ul, .fancy-ol {
          padding-left: 2em;
          margin-bottom: 1em;
        }

        .fancy-li {
          margin-bottom: 0.5em;
          position: relative;
        }

        .fancy-ul .fancy-li::before {
          content: '▹';
          color: #4a90e2;
          position: absolute;
          left: -1.5em;
        }

        .fancy-ol {
          counter-reset: item;
        }

        .fancy-ol .fancy-li {
          counter-increment: item;
        }

        .fancy-ol .fancy-li::before {
          content: counter(item) ".";
          color: #4a90e2;
          position: absolute;
          left: -2em;
          width: 1.5em;
          text-align: right;
        }

        .fancy-blockquote {
          border-left: 4px solid #4a90e2;
          padding-left: 1em;
          margin: 1em 0;
          font-style: italic;
          background: rgba(74, 144, 226, 0.1);
          padding: 1em;
          border-radius: 0 5px 5px 0;
        }

        .fancy-code {
          background-color: #9597c9;
          padding: 0.2em 0.4em;
          border-radius: 3px;
          font-family: 'Courier New', Courier, monospace;
          font-size: 0.9em;
        }

        .fancy-img-container {
          margin: 1em 0;
          text-align: center;
        }

        .fancy-img {
          max-width: 100%;
          border-radius: 5px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        .fancy-img-caption {
          display: block;
          margin-top: 0.5em;
          font-style: italic;
          color: #666;
        }

        .fancy-table-container {
          overflow-x: auto;
          margin: 1em 0;
        }

        .fancy-table {
          width: 100%;
          border-collapse: separate;
          border-spacing: 0;
        }

        .fancy-table th,
        .fancy-table td {
          border: 1px solid #e0e0e0;
          padding: 0.5em;
          text-align: left;
        }

        .fancy-table th {
          background-color: #4a90e2;
          color: white;
          font-weight: bold;
        }

        .fancy-table tr:nth-child(even) {
          background-color: #f9f9f9;
        }

        .fancy-table tr:hover {
          background-color: #f0f0f0;
        }

        .grid {
          display: grid;
        }

        .grid-cols-1 {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        @media (min-width: 768px) {
          .md\:grid-cols-2 {
            grid-template-columns: repeat(2, minmax(0, 1fr));
          }
        }

        .gap-6 {
          gap: 1.5rem;
        }

        .mb-4 {
          margin-bottom: 1rem;
        }

        .bg-gray-800 {
          background-color: rgba(31, 41, 55, 0.8);
        }

        .rounded-lg {
          border-radius: 0.5rem;
        }

        .p-4 {
          padding: 1rem;
        }

        .shadow-lg {
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
        }

        .text-xl {
          font-size: 1.25rem;
          line-height: 1.75rem;
        }

        .font-bold {
          font-weight: 700;
        }

        .mb-3 {
          margin-bottom: 0.75rem;
        }

        .flex {
          display: flex;
        }

        .items-center {
          align-items: center;
        }

        .ml-2 {
          margin-left: 0.5rem;
        }

        .space-y-2 > * + * {
          margin-top: 0.5rem;
        }

        .text-left {
          text-align: left;
        }

        .w-full {
          width: 100%;
        }

        .p-2 {
          padding: 0.5rem;
        }

        .rounded {
          border-radius: 0.25rem;
        }

        .hover\:bg-gray-700:hover {
          background-color: rgba(55, 65, 81, 0.8);
        }

        .transition-colors {
          transition-property: background-color, border-color, color, fill, stroke;
        }

        .duration-200 {
          transition-duration: 200ms;
        }

        .text-center {
          text-align: center;
        }

        .mt-2 {
          margin-top: 0.5rem;
        }

        .text-xs {
          font-size: 0.75rem;
        }

        .text-gray-300 {
          color: #d1d5db;
        }

        .tracking-wide {
          letter-spacing: 0.025em;
        }
      `}</style>
      <div className="flex items-center justify-between mb-8">
        <div>
          <h1 className="text-4xl font-bold animate-gradient">Value Finance AI</h1>
          <p style={{textAlign: 'center'}} className="text-xs text-gray-300 tracking-wide">The <i>only</i> AI with comprehensive SEC data</p>
        </div>
        <div className="flex items-center">
          <div className="flex items-center bg-gray-800 rounded-full px-4 py-2 mr-4">
            <TrendingUp size={20} className="text-green-400 mr-2" />
            
            <select
              value={selectedStock}
              onChange={handleStockChange}
              className="bg-transparent text-white focus:outline-none"
            >
              {stocks.map((stock) => (
                <option key={stock} value={stock}>{stock}</option>
              ))}
            </select>
            {!user && <Lock size={16} className="ml-2 text-yellow-500" />}
          </div>
          {user ? (
            <button onClick={handleLogout} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-full flex items-center">
              <LogOut size={20} className="mr-2" /> Logout
            </button>
          ) : (
            <button onClick={() => setShowAuthModal(true)} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full flex items-center">
              <LogIn size={20} className="mr-2" /> Login
            </button>
          )}
        </div>
      </div>
      
      {/* <div className="flex flex-grow space-x-6"> */}
        {/* Stock Data Table */}
        {/* <div className="w-3/4">
          <h2 className="text-2xl font-bold mb-4 animate-gradient">Stock Data</h2>
          <StockDataTable />
        </div> */}
      <div className="flex-grow">
        {/* Chat Interface */}
        <div className="w-full flex flex-col">
          <div className="flex justify-between items-center mb-2">
            <h2 className="text-2xl font-bold animate-gradient">Chat</h2>
            {chatHistory.length > 0 && (
              <button
                onClick={clearChat}
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded-full flex items-center"
                title="Clear chat"
              >
                <Trash2 size={16} className="mr-1" /> Clear
              </button>
            )}
          </div>
          <div className="flex-grow overflow-auto mb-6 p-4 rounded-lg backdrop-blur-sm">   
            {chatHistory.length === 0 ? (
              <div className="flex flex-col items-center justify-center h-full text-gray-400">
                <MessageSquare size={48} className="mb-4" />
                <p>No messages yet. Start chatting about {selectedStock || 'a stock'}!</p>
              </div>
            ) : (
              <>
                {chatHistory.map((chat, index) => (
                  <div key={index} className={`mb-4 ${chat.type === 'user' ? 'text-right' : 'text-left'}`}>
                    <span className={`inline-block p-3 rounded-lg ${
                      chat.type === 'user' 
                        ? 'bg-gradient-to-r from-blue-600 to-blue-400' 
                        : 'bg-gradient-to-r from-purple-600 to-purple-400'
                    }`}>
                      {chat.type === 'ai' ? renderMarkdown(chat.content) : chat.content}
                    </span>
                  </div>
                ))}
                {isLoading && (
                  <div className="flex justify-center items-center mt-4">
                    <Loader size={24} className="animate-spin text-blue-500" />
                    <span className="ml-2">AI is thinking...</span>
                  </div>
                )}
              </>
            )}
          </div>
          {chatHistory.length === 0 && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
              {Object.entries(suggestedPrompts).map(([category, prompts]) => (
                <div key={category} className="bg-gray-800 rounded-lg p-4 shadow-lg">
                  <h3 className="text-xl font-bold mb-3 flex items-center">
                    {getCategoryIcon(category)}
                    <span className="ml-2">{getCategoryTitle(category)}</span>
                  </h3>
                  <ul className="space-y-2">
                    {prompts.map((prompt, index) => (
                      <li key={index}>
                        <button
                          onClick={() => handlePromptClick(prompt)}
                          className="text-left w-full p-2 rounded hover:bg-gray-700 transition-colors duration-200"
                        >
                          {prompt}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          )}
          <form onSubmit={handleSubmit} className="flex bg-gray-800 rounded-full overflow-hidden">
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Ask about GME..."
              className="flex-grow bg-transparent text-white p-4 focus:outline-none"
            />
            <button type="submit" id="hidden" className="bg-gradient-to-r from-blue-500 to-purple-500 p-4 rounded-full">
              <Send size={24} />
            </button>
          </form>
        </div>
      </div>

      <footer className=" text-white py-8 px-6">
        <div className="max-w-6xl mx-auto">
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <p className="text-sm">&copy; Copyright 2024 ValueFin. All Rights Reserved.</p>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-2">Our Company</h3>
              <ul className="space-y-1">
                <li><a href="#" className="text-blue-300 hover:text-blue-100">About Us</a></li>
                <li><a href="#" className="text-blue-300 hover:text-blue-100 flex items-center">
                </a></li>
                <li><a href="#" className="text-blue-300 hover:text-blue-100">Discord</a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-2">Legal</h3>
              <ul className="space-y-1">
                <li><a href="#" className="text-blue-300 hover:text-blue-100">Privacy Policy</a></li>
                <li><a href="#" className="text-blue-300 hover:text-blue-100">Terms of Use</a></li>
                <li><a href="#" className="text-blue-300 hover:text-blue-100">General Risk Disclaimer</a></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>


      {(showAuthModal || showPremiumPrompt) && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-gray-800 p-6 rounded-lg max-w-sm w-full">
            {showPremiumPrompt ? (
              <>
                <h2 className="text-2xl font-bold mb-4">Upgrade to Premium</h2>
                <p className="mb-4">Sign up for a premium account to access all stocks and unlimited messages!</p>
                <button 
                  onClick={() => {setShowPremiumPrompt(false); setShowAuthModal(true); setAuthMode('signup');}}
                  className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mb-2"
                >
                  Sign Up for Premium
                </button>
                <button 
                  onClick={() => setShowPremiumPrompt(false)}
                  className="w-full bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                >
                  Continue with GME Only
                </button>
              </>
            ) : (
              <>
                <h2 className="text-2xl font-bold mb-4">{authMode === 'login' ? 'Login' : 'Sign Up'}</h2>
                <form onSubmit={handleAuth}>
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full p-2 mb-4 bg-gray-700 rounded"
                  />
                  <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full p-2 mb-4 bg-gray-700 rounded"
                  />
                  <button type="submit" className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                    {authMode === 'login' ? 'Login' : 'Sign Up'}
                  </button>
                </form>
                <button 
                  onClick={() => setAuthMode(authMode === 'login' ? 'signup' : 'login')}
                  className="mt-4 text-blue-400 hover:text-blue-300"
                >
                  {authMode === 'login' ? 'Need an account? Sign Up' : 'Already have an account? Login'}
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default StockAIChat;
