const BACKEND_URL = "http://127.0.0.1:5000";

const fetchFromBackend = async (endpoint, method = 'GET', body = null) => {
  const url = `${BACKEND_URL}${endpoint}`;
  const options = {
    method,
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  const response = await fetch(url, options);
  
  if (!response.ok) {
    const errorText = await response.text();
    console.error('Error response:', errorText);
    throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
  }

  return response;
};

export { BACKEND_URL, fetchFromBackend };
